import React, { useEffect, useState } from 'react';

import { UiAccordionPanel } from '@powdr/components';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

import { DorLiftTableRender } from './dor-lift-table-render';
import {
  DataShape,
  FiltersShape,
  SettingsShape,
  ThemeShape,
} from './dor-prop-types';
import { DorSectorDetailsRender } from './dor-sector-details-render';
import { DorTrailTableRender } from './dor-trail-table-render';
import { FilterNoResults } from './styles';

export const DorTrailSectorRender = ({
  data, settings, theme, filters,
}) => {
  const [lifts, setLifts] = useState([]);
  const [trails, setTrails] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredLifts = filterLiftTrailItemsBySeason(data?.lifts, settings?.season)
        ?.filter((f) => (filters.sector.liftIds?.length > 0
          ? filters.sector.liftIds
            ?.includes(f.id)
          : filters.sector.selectedLiftIds?.length === 0));

      const filteredTrails = filterLiftTrailItemsBySeason(data?.trails, settings?.season)
        ?.filter((f) => !settings
          ?.filterOutTrailTypes
          ?.includes(f.type))
        ?.filter((f) => (filters.sector.trailIds?.length > 0
          ? filters.sector.trailIds
            ?.includes(f.id)
          : filters.sector.selectedTrailIds?.length === 0));

      setTrails(filteredTrails);
      setLifts(filteredLifts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    filters.sector.trailIds,
    filters.sector.liftIds,
    filters.sector.selectedLiftIds,
    filters.sector.selectedTrailIds,
  ]);

  if (!data?.sectors) return null;

  return (
    (trails?.length > 0 || lifts?.length > 0)
      ? data?.sectors?.map((sector) => (
        [...lifts, ...trails]
          ?.some((item) => item.sector.id === sector.id
          || item.sector.uuid === sector.id)
      ) && (
      <UiAccordionPanel
        key={sector.id} //
        panelId={`${sector.name}`}
        label={sector.name}
        isOpen={data.sectors.indexOf(sector) < 4}
        style={{ zIndex: 100 - data.sectors.indexOf(sector) }}
        extraDetailsRender={
              (
                <DorSectorDetailsRender
                  data={data}
                  settings={settings}
                  sector={sector}
                  theme={theme}
                />
              )
            }
      >

        {lifts?.length > 0 ? (
          <DorLiftTableRender
            rows={lifts
              ?.filter((f) => f?.sector?.uuid === sector.id)}
            theme={theme.sectorLiftHeader.class}
            settings={settings}
          />
        ) : <FilterNoResults>No Lifts results with given filters</FilterNoResults>}

        {trails?.length > 0 ? (
          <DorTrailTableRender
            rows={trails
              ?.filter((f) => f?.sector?.id === sector.id)}
            settings={settings}
          />
        ) : <FilterNoResults>No Trail results with given filters</FilterNoResults>}

      </UiAccordionPanel>
      ))
      : <FilterNoResults>No results with given filters</FilterNoResults>
  );
};

DorTrailSectorRender.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape,
  filters: FiltersShape.isRequired,
};

export default DorTrailSectorRender;
