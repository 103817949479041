import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { DropdownButton, UiAccordion, UiDrawer } from '@powdr/components';
import {
  ButtonText, ColorProfiles, FilterDefaultState, trailFilter,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';

import { DorCheckboxGroup } from './dor-checkbox-group';
import {
  DataShape,
  SettingsShape,
} from './dor-prop-types';
import {
  CheckboxGroupWrapper,
  FilterGroupTitle, FilterWrapper, StyledDorDrawer, StyledLayoutFilters,
} from './styles';

export const DorLiftsTrailsFilters = ({
  data,
  settings,
  onFilterChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    toggleBodyScroll,
  } = useContext(AppContext);

  const handleCheckboxClick = (e) => {
    onFilterChange(e, 'trail');
  };

  const handleOnDrawerOpen = () => {
    setIsDrawerOpen(true);
    toggleBodyScroll(true);
  };

  const handleOnDrawerClose = () => {
    setIsDrawerOpen(false);
    toggleBodyScroll(false);
  };

  const checkboxGroups = () => trailFilter
    ?.map((grp) => (!settings.hideFilterDrawer
      ?.includes(grp.id) ? (
        <CheckboxGroupWrapper>
          <FilterGroupTitle>
            {grp.label}
          </FilterGroupTitle>
          <DorCheckboxGroup
            id={grp.id}
            layout="list"
            inputLabelTitle={grp.label}
            onFiltersChange={handleCheckboxClick}
            colorProfile={ColorProfiles.PRIMARY}
            checkInputsFields={
            (grp.fields[settings?.property] || grp.fields?.default)
              ?.filter((f) => ![
                ...settings.hideTrailStatus || [],
                ...settings.hideTrailDifficulty || [],
                ...settings.hideTrailFeature || [],
                ...settings.hideTrailFeatureDiff || [],
                ...settings.hideTrailFeatureSize || [],
                ...settings.hideTrailGroomedStatus || [],
              ].includes(f.id))
}
          />
        </CheckboxGroupWrapper>
      ) : null));

  return (
    <StyledLayoutFilters top={`${settings.isDesktop ? settings.drawerStickyOffset : 20}`}>
      <StyledDorDrawer colorProfile={ColorProfiles.PRIMARY}>
        {(settings.isMobile) && (
          <UiDrawer
            isDrawerOpen={isDrawerOpen}
            isDrawerEnabled={settings.isMobile}
            handleDrawOpen={handleOnDrawerOpen}
            handleCanvasClose={handleOnDrawerClose}
            drawerWidth={310}
          >
            {settings.generalDrawr.FITLER_HEADER
              && data.conditionsData?.parkUpdate
              && (
                <div className="drawr-fitler-header">
                  <h3>
                    Total Features:
                    {data.conditionsData?.parkUpdate[0]?.total_features || 0}
                  </h3>
                </div>
              )}
            <UiAccordion
              panelDefaultState={(settings?.filterDefaultState === FilterDefaultState.OPEN)}
            >
              {checkboxGroups()}
            </UiAccordion>
          </UiDrawer>
        )}
        {!settings.isMobile && (
          <DropdownButton
            closedButtonText={ButtonText.SHOW_FILTERS}
            openButtonText={ButtonText.HIDE_FILTERS}
            openButtonAriaLabel="Hide all search filters and close dropdown menu"
            closedButtonAriaLabel="Open dropdown menu and show all search filters"
            defaultState={(settings?.filterDefaultState === FilterDefaultState.OPEN)}
          >
            <FilterWrapper>
              {checkboxGroups()}
            </FilterWrapper>
          </DropdownButton>
        )}
      </StyledDorDrawer>
    </StyledLayoutFilters>
  );
};

DorLiftsTrailsFilters.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

DorLiftsTrailsFilters.defaultProps = {};
