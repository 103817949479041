import {
  DorAlert, DorSnowReport, DorTrailReport, DorLiftsTrails, DorWebCam,
  DorWeather, DorLiveWeather, DorLive24Table, ChatSatisfi, ChatTrackhs, CompareCards,
  ContentBlock, ContentBlockGroup, ContentBlockCarousel, SectionContentTabs, ContentBlockHero,
  Grid, QrQueryCards, ProductFinder, UiColumn, UiFooter, UiHeader, UiSection, UiHtml, UiImage,
  UiEmbed, UiForm, UiTable, UiIframe, Snapwidget, EmailSignup, Webcam, CollapsePanel, View,
  FormFormbucket, FormSilverpop, BookingWidget, Slider, GlobalContent, EcommerceWidget,
  BannerAd, FloatingImageSection, DynamicComponent, HotspotGroup, JuicerFeed, DorSectorReport,
  LinkList, PromotionList, GlobalTable, ImageGrid,
  CollapsePanelGroup, ColumnGroup, DorDisplayBoard, BannerCarousel,
  DorWebcams, DorMiniAlert, Sidebar, PromotionTray,
  DorParking, DorMeasurements,
  TvForecast, TvMeasurements, TvGrid, TvWebcams, TvAlerts,
} from '@powdr/components';
import { Components } from '@powdr/constants';

export const DynamicComponents = {
  [Components.CONTENT_BLOCK_HERO]: ContentBlockHero,
  [Components.EMAIL_SIGNUP]: EmailSignup,
  [Components.COLLAPSE_PANEL]: CollapsePanel,
  [Components.UI_SECTION]: UiSection,
  [Components.UI_HTML]: UiHtml,
  [Components.UI_HEADER]: UiHeader,
  [Components.UI_FOOTER]: UiFooter,
  [Components.UI_IMAGE]: UiImage,
  [Components.UI_FORM]: UiForm,
  [Components.FORM_FORMBUCKET]: FormFormbucket,
  [Components.FORM_SILVERPOP]: FormSilverpop,
  [Components.GRID]: Grid,
  [Components.GLOBAL_CONTENT]: GlobalContent,
  [Components.CONTENT_BLOCK]: ContentBlock,
  [Components.CHAT_SATISFI]: ChatSatisfi,
  [Components.CHAT_TRACKHS]: ChatTrackhs,
  [Components.CONTENT_BLOCK_GROUP]: ContentBlockGroup,
  [Components.CONTENT_BLOCK_CAROUSEL]: ContentBlockCarousel,
  [Components.SECTION_CONTENT_TABS]: SectionContentTabs,
  [Components.QR_QUERY_CARDS]: QrQueryCards,
  [Components.COMPARE_CARDS]: CompareCards,
  [Components.PRODUCT_FINDER]: ProductFinder,
  [Components.DOR_ALERT]: DorAlert,
  [Components.DOR_DISPLAY_BOARD]: DorDisplayBoard,
  [Components.DOR_PARKING]: DorParking,
  [Components.DOR_SNOW_REPORT]: DorSnowReport,
  [Components.DOR_TRAIL_REPORT]: DorTrailReport,
  [Components.DOR_LIFTS_TRAILS]: DorLiftsTrails,
  [Components.DOR_SECTOR_REPORT]: DorSectorReport,
  [Components.DOR_WEATHER]: DorWeather,
  [Components.DOR_LIVE_WEATHER]: DorLiveWeather,
  [Components.DOR_LIVE_24_TABLE]: DorLive24Table,
  [Components.DOR_WEB_CAM]: DorWebCam,
  [Components.SNAPWIDGET]: Snapwidget,
  [Components.BOOKING_WIDGET]: BookingWidget,
  [Components.ECOMMERCE_WIDGET]: EcommerceWidget,
  [Components.UI_COLUMN]: UiColumn,
  [Components.UI_EMBED]: UiEmbed,
  [Components.VIEW]: View,
  [Components.UI_TABLE]: UiTable,
  [Components.UI_IFRAME]: UiIframe,
  [Components.WEBCAM]: Webcam,
  [Components.SLIDER]: Slider,
  [Components.BANNER_AD]: BannerAd,
  [Components.FLOATING_IMAGE_SECTION]: FloatingImageSection,
  [Components.DYNAMIC_COMPONENT]: DynamicComponent,
  [Components.HOTSPOT_GROUP]: HotspotGroup,
  [Components.JUICER_FEED]: JuicerFeed,
  [Components.LINK_LIST]: LinkList,
  [Components.IMAGE_GRID]: ImageGrid,
  [Components.PROMOTION_LIST]: PromotionList,
  // [Components.NAVIGATION_CTA]: NavigationCTA,
  // [Components.WEATHER_INDICATOR]: WeatherIndicator,
  [Components.GLOBAL_TABLE]: GlobalTable,
  [Components.COLLAPSE_PANEL_GROUP]: CollapsePanelGroup,
  [Components.COLUMM_GROUP]: ColumnGroup,
  [Components.BANNER_CAROUSEL]: BannerCarousel,
  [Components.DOR_WEBCAMS]: DorWebcams,
  [Components.DOR_MINI_ALERT]: DorMiniAlert,
  [Components.SIDEBAR]: Sidebar,
  [Components.PROMOTION_TRAY]: PromotionTray,
  [Components.DOR_MEASUREMENTS]: DorMeasurements,

  [Components.TV_MEASUREMENTS]: TvMeasurements,
  [Components.TV_FORECAST]: TvForecast,
  [Components.TV_ALERTS]: TvAlerts,
  [Components.TV_GRID]: TvGrid,
  [Components.TV_WEBCAMS]: TvWebcams,
};
