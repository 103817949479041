import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import {
  UiEmbed, Link, PriceRange, WysiwygStyleWrapper,
} from '@powdr/components';
import {
  BlockTypes,
  Components,
  DorPlaceStatus,
  FontFamily,
  FontSize,
  ThemeProps as Prop,
  StaticProfiles,
  TextTransformOptions,
} from '@powdr/constants';
import { usePlaceStatus } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, formatDate, phoneNumberPrettify, getFontStyles,
  getDorStatusColor,
  capitalizeAllWords,
} from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK;

// styled components
const ContentBlockBasic = styled.div`
  position: relative;

  .image-container {
    position: relative;
  }

  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  small:not(.template) {
    margin-bottom: 10px;
  }

  h3 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 1.5, undefined, CPNT, 'header')};
  }

  p {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined, CPNT, 'body')};
  }

  a {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
  }

  small.template {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined, CPNT, 'body')};
  }
`;

const ImageContainer = styled(Link)`
  display: block;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 15px;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 50px;
  margin-bottom: 10px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  line-height: 1.2;
  padding: 30px 0;
`;

const AILeft = styled.div``;

const CustomPriceRange = styled(PriceRange)`
  font-weight: bold;
`;

const AIRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`;

const AIRightTop = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  //padding-bottom: 25px;
`;

const AIRightBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusHeader = styled.small``;

const StatusValue = styled.small`
  font-weight: normal;
  color: ${({ $status }) => getDorStatusColor(
    DorPlaceStatus,
    $status,
    CPNT,
    StaticProfiles.ALERTS,
  )} !important;
`;

const Date = styled.p`
  text-align: left;
  line-height: 1.1;
  font-weight: bold;
`;

const Tags = styled.p`
  text-align: right;
  font-weight: bold;
  line-height: 1.1;
  text-transform: capitalize;
`;

export const TvBasic = ({
  title, copy, image, date, endDate,
  formattedContent, tags, status,
  hours, phoneNumber, phoneNumberDetails,
  location, locationDetails, priceRange,
}) => {
  const statusResult = usePlaceStatus(status);

  return (
    <ContentBlockBasic>
      {(date || tags) && (
      <TagContainer>
        {(date) && <Date>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).year}${(endDate && date !== endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).year}` : ''}`}</Date>}
        {(tags) && <Tags>{tags}</Tags>}
      </TagContainer>
      )}
      {(image) && (
      <ImageContainer>
        <Image src={image.src} alt={image?.altText} />
      </ImageContainer>
      )}
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(hours || phoneNumber || location || status) && (
      <AdditionalInfoContainer>
        <AILeft>
          {(priceRange?.length > 0) && <CustomPriceRange max={4} value={priceRange.length || 1} character="$" />}
          <div>{ReactHtmlParser(hours)}</div>
        </AILeft>
        <AIRight>
          {(statusResult) && (
            <AIRightTop>
              <StatusHeader className="template">Status</StatusHeader>
              <StatusValue className="template" $status={statusResult}>{capitalizeAllWords(statusResult)}</StatusValue>
            </AIRightTop>
          )}
          {(location || locationDetails) && (
            <AIRightTop>
              {(location) && <small className="template">{location}</small>}
              {(locationDetails) && <small className="template">{locationDetails}</small>}
            </AIRightTop>
          )}
          {(phoneNumber || phoneNumberDetails) && (
          <AIRightBottom>
            {(phoneNumber) && <small className="template"><a href={`tel:${phoneNumber}`}>{phoneNumberPrettify(phoneNumber)}</a></small>}
            {(phoneNumberDetails) && <small className="template">{phoneNumberDetails}</small>}
          </AIRightBottom>
          )}
        </AIRight>
      </AdditionalInfoContainer>
      )}
      {(copy) && <p className="cb-copy">{ReactHtmlParser(copy)}</p>}
      {(formattedContent) && <WysiwygStyleWrapper className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</WysiwygStyleWrapper>}
    </ContentBlockBasic>
  );
};

TvBasic.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
